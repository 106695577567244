import React, { Component } from "react";
import { Dropdown, Button, Icon } from "react-materialize";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ChatHelpers } from "./ChatHelpers";
import * as _ from "lodash";
class ChatRoomSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userTabs: [],
            recipient: null
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.errors) {
            return {
                errors: props.errors
            };
        }
        const userTabs = ChatHelpers.mapMessagesToUserTabs(props.messages, state.userTabs, props.auth.user);
        if (userTabs.length < 1 && state.recipient) {
            userTabs.push(state.recipient);
        };
        if (!_.isEqual(state.userTabs, userTabs)) {
            state.userTabs = userTabs;
        }
        if (!_.isEqual(state.recipient, props.recipient)) {
            state.recipient = props.recipient;
        }
        return state;
    }

    getChatTab = (title, recipient) => (
        <a href={`#${title}`}
            key={`${recipient ? recipient._id : 'public'}_tablink`}
            onClick={() => {
                this.setState({ recipient: recipient });
                this.props.updateUser(recipient);
            }}
            className={'chat-room-selection-item'}>
            {
                recipient ? (
                    <img
                        src={recipient.avatar} 
                        alt={recipient.name}
                        className={'message-icon'}
                    />    
                ) : (
                    <Icon>
                        public
                    </Icon>
                )
            }
            {recipient ? title : this.context.t('COMMON.PUBLIC')} 
        </a>
    );

    getUserTabs = () => {
        const publicTab = ['Public', null];
        return (this.state.userTabs.length > 0
            ? [
                ...[publicTab],
                ...this.state.userTabs.map(user => [ user.name, user ])
            ] : [
                publicTab
            ]).map(user => this.getChatTab(user[0], user[1]));
    };

    render() {
        return (
            <Dropdown
                className={this.props.className}
                options={{
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    container: null,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }}
                trigger={
                    <Button
                        node="button"
                        className={`btn btn-large waves-effect waves-light hoverable amber black-text ${this.props.className} ${this.props.isMobile ? 'btn-floating' : ''}`}
                    >
                        {
                        this.props.isMobile
                            ? this.state.recipient
                                ? (
                                    <img
                                        src={this.state.recipient.avatar}
                                        alt={this.state.recipient.name}
                                    />
                                ) : (
                                    <Icon>
                                        public
                                    </Icon>
                                )
                            : this.state.recipient ? this.state.recipient.name : this.context.t("COMMON.PUBLIC")
                        }
                    </Button>}
            >
                {this.getUserTabs()}
            </Dropdown>
        );
    }
}
ChatRoomSelector.propTypes = {
    auth: PropTypes.object.isRequired,
    messages: PropTypes.array,
    recipient: PropTypes.object,
    updateUser: PropTypes.func,
    isMobile: PropTypes.bool
};
ChatRoomSelector.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default withRouter(connect(
    mapStateToProps,
    { }
)(ChatRoomSelector));