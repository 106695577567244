import React, { Component } from "react";
import PropTypes from "prop-types";
class Button extends Component {
    render() {
        return (
            <button
                type={this.props.type}
                onClick={this.props.onClick}
                className={`${ButtonClasses[this.props.buttonType]} ${this.props.className}`}
            >
                {this.props.children}
            </button>
        );
    }
}
Button.propTypes = {
    buttonType: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func
};
export default Button;
export const ButtonClasses = {
    primary: "btn btn-large waves-effect waves-light hoverable amber black-text",
    secondary: "btn btn-large btn-flat waves-effect grey darken-4 white-text"
}