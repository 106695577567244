import React, { Component } from "react";
import './Logo.scss';
class Logo extends Component {

    render() {
        return (
            <span className="logo-wrapper">
                <span className="logo amber-text">
                    <span className="logo-fuke">
                        フケ
                    </span>
                    <span className="logo-sen">
                        専
                    </span>
                </span>
            </span>
        );
    }
}
export default Logo;