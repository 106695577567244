import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING
} from "./types";
// Register User
export const registerUser = (userData, history, callback) => dispatch => {
    axios
        .post("/api/users/register", userData)
        .then(res => {
            callback(res);
            history.push("/login?registered");
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Update User
export const updateUser = (userData, history) => dispatch => {
    axios
        .post("/api/users/update", userData)
        .then(res => history.push("/dashboard"))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Change password
export const pwdChange = (data, callback) => dispatch => {
    axios
        .post("/api/users/pwd-change", data)
        .then(res => callback(res))
        .catch(err => {
            return dispatch({
                type: GET_ERRORS,
                payload: err.response ? err.response.data : {}
            })
        }
        );
};
// Reset password
export const pwdReset = (data, callback) => dispatch => {
    axios
        .post("/api/users/pwd-reset", data)
        .then(res => callback(res))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Login - get user token
export const loginUser = (userData, callback) => dispatch =>{
    axios
        .post("/api/users/login", userData)
        .then(res => {
            // Save to localStorage
            // Set token to localStorage
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            // Set token to Auth header
            setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token);
            // Set current user
            callback(userData);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err => {
            callback(err.response.data);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        });
};
// Find user
export const findUser = userData => axios
    .post("/api/users/find", userData);
// Update last update
export const updateLastUpdate = userData => {
    axios
        .post("/api/users/tick", userData)
};
// Set logged in user
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};
// User loading
export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};
// Log user out
export const logoutUser = () => dispatch => {
    // Remove token from local storage
    localStorage.removeItem("jwtToken");
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to empty object {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
};