export class ChatHelpers {

    static getFilteredList = (messages, sender, recipient) => messages.filter(message => recipient
        ? message.to
            && (
                (message.to._id === recipient._id && message.from._id === sender._id)
                || (message.from._id === recipient._id && message.to._id === sender._id)
            )
        : (
            (!message.to)
        )
    );

    static mapMessagesToUserTabs = (messages, userTabs, currentUser) => {
        if (currentUser) {
            const users = messages
                .filter(message => {
                    if (message.to) {
                        return message.to &&
                        message.to._id !== message.from._id &&
                        (message.to._id === currentUser.id ||
                        message.from._id === currentUser.id);
                    }
                    return false;
                })
                .map(message => message.from._id === currentUser.id
                        ? message.to
                        : message.from
                );
            return [
                    ...userTabs,
                    ...users
                ]
                .sort((a, b) => (a._id < b._id) ? 1 : -1)
                .filter((item, pos, ary) => !pos || item._id !== ary[pos - 1]._id);    
        }
        return userTabs;
    }

    static mapMessagesToUsers = messages => {
        const userMessages = [];
        let tmpMessages = [];
        let currentUser = null;
        if (messages && messages.length) {
            messages
                .sort((a, b) => a.date > b.date ? 1 : -1)
                .forEach(message => {
                if (currentUser && currentUser._id !== message.from._id) {
                    userMessages.push({
                        user: currentUser,
                        messages: tmpMessages
                    });
                    tmpMessages = [];
                }
                currentUser = message.from;
                tmpMessages.push(message);
            });
            if (currentUser) {
                userMessages.push({
                    user: currentUser,
                    messages: tmpMessages
                });
            }
        }
        return userMessages
    }

}
