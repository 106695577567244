import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { Link } from "react-router-dom";
import Button, { ButtonClasses } from "../form-elements/Button";
import { Dropdown, Icon, Button as MaterializeButton } from "react-materialize";
import { withRouter } from 'react-router-dom';
import Logo from './Logo';
import {setLanguage} from "redux-i18n"
import './Navbar.scss';
class Navbar extends Component {

    static onLogoutClick = (props, context, event) => {
        if (window.confirm(context.t('NAV.CONFIRM_LOGOUT'))) {
            event.preventDefault();
            props.logoutUser();
            props.history.push('/');    
        }
    };

    static getDerivedStateFromProps = (props, state) => {
        state.user = props.auth && props.auth.user ? props.auth.user : null;
        state.unreadMessageCount = props.messages.filter(message => {
            return !message.openingDate;
        }).length;
        state.messageCount = props.messages.length;
        return state;
    }

    constructor(props) {
        super(props);
        this.state = {
            title: 'HOME.TITLE',
            user: props.auth && props.auth.user ? props.auth.user : null,
            unreadMessageCount: 0,
            messageCount: 0
        };
    }

    loggedInNavigation = () => [{
        url: '/dashboard',
        linkText: 'DASHBOARD'
    }, {
        url: '/chat',
        linkText: 'CHAT'
    }, ...(this.state.messageCount > 0
        ? [{
            url: '/messagelist',
            linkText: 'MESSAGES',
            bubble: this.state && this.state.unreadMessageCount > 0
                ? (
                        <span className="message-counter">
                            { this.state.unreadMessageCount }
                        </span>
                ) : null
        }] : []
    ), {
        url: '/profile',
        linkText: 'PROFILE'
    }, {
        onClick: Navbar.onLogoutClick,
        linkText: 'LOGOUT'
    }];

    loggedOutNavigation = () => [{
        url: '/register',
        linkText: 'REGISTER'
    }, {
        url: '/login',
        linkText: 'LOGIN'
    }];

    hiddenLinks = () => [{
        url: '/pwd-change',
        linkText: 'PWD_CHANGE'
    }, {
        url: '/pwd-reset',
        linkText: 'PWD_RESET'
    }, {
        url: '/home',
        linkText: 'HOME'
    }];

    setTitle = () => [
        ...this.loggedInNavigation(),
        ...this.loggedOutNavigation(),
        ...this.hiddenLinks()
    ]
        .filter(link => {
            return this.props.location.pathname.indexOf(link.url) > -1;
        })
        .forEach(link => {
            this.setState({
                title: `NAV.${link.linkText}`
            });
        })

    componentDidMount() {
        this.setTitle();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setTitle();
        }
    }

        pageHasNavEntry = () => [
        ...this.loggedInNavigation(),
        ...this.loggedOutNavigation()
    ].filter(link => this.props.location.pathname.indexOf(link.url) > -1).length > 0;

    authButtons() {
        const { user } = this.props && this.props.auth ? this.props.auth : null;
        return (
            user.name
                ? this.loggedInNavigation()
                : this.loggedOutNavigation()
            )
            .map(link => link.url ? (
                    <Link
                        key={`${link.linkText}_link`}
                        to={link.url}
                        className={`${ButtonClasses.secondary} ${this.props.location.pathname.indexOf(link.url) > -1 ? ' active' : ''}`}
                    >
                        {this.context.t(`NAV.${link.linkText}`)}
                        {link.bubble ? link.bubble : ''}
                    </Link>
                ) : (
                    <Button
                        key={`${link.linkText}_link`}
                        buttonType={'primary'}
                        className={`primary`}
                        onClick={event => {
                            link.onClick(this.props, this.context, event);
                        }}
                    >
                        {this.context.t(`NAV.${link.linkText}`)}
                    </Button>
                )
            );
    }

    langButtons() {

        const languages = {
            ja: '日本語',
            en: 'English',
            de: 'Deutsch',
            fr: 'Français',
            it: 'Italiano',
            es: 'Espanol',
            ru: 'русский'
        }
        return Object.keys(languages)
            .map(lang => (
                <Button
                    key={`${lang}_link`}
                    buttonType={'primary'}
                    className={`primary`}
                    onClick={() => {
                        this.props.setLanguage(lang);
                    }}
                >
                    {languages[lang]}
                </Button>
            ));
    }
    render() {
        return (
            <div className="row navbar-fixed">
                <nav className="z-depth-0 container header-nav">
                    <div className="header-nav-wrapper grey darken-4">
                        <h4 className="hide-on-large-only mobile-title">
                            <span className="amber-text">
                                {this.context.t(this.state.title)}
                            </span>
                        </h4>
                        <Dropdown
                            options={{
                                alignment: 'right',
                                autoTrigger: true,
                                closeOnClick: true,
                                constrainWidth: false,
                                container: null,
                                coverTrigger: true,
                                hover: false,
                                inDuration: 150,
                                onCloseEnd: null,
                                onCloseStart: null,
                                onOpenEnd: null,
                                onOpenStart: null,
                                outDuration: 250
                            }}
                            trigger={
                                <a>
                                <Logo></Logo>

                                </a>
                            }
                        >
                            { this.langButtons() }
                        </Dropdown>
                        <div className="header-nav-desktop hide-on-med-and-down right">
                            {
                                this.pageHasNavEntry()
                                    ? '' : (
                                        <Link
                                            to={this.props.location.pathname}
                                            className={`${ButtonClasses.secondary} active`}
                                        >
                                            {this.context.t(this.state.title)}
                                        </Link>
                                    )
                            }
                            { this.authButtons() }
                        </div>
                        <div className="hide-on-large-only right">
                            <Dropdown
                                options={{
                                    alignment: 'right',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    container: null,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }}
                                trigger={
                                    <MaterializeButton
                                        node="button"
                                        className={'btn btn-floating btn-large waves-effect waves-light hoverable amber'}
                                    >
                                        <Icon medium>
                                            menu
                                        </Icon>
                                    </MaterializeButton>}
                            >
                                { this.authButtons() }
                            </Dropdown>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}
Navbar.propTypes = {
    location: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};
Navbar.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default withRouter(connect(
    mapStateToProps,
    { logoutUser, setLanguage }
)(Navbar));
