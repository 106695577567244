import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import {fromLonLat, toLonLat, transform} from 'ol/proj';
import Icon from 'ol/style/Icon';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import MapFinder from './MapFinder';
import {apply} from 'ol-mapbox-style';
import * as mapStyle from '../../map-style.json'
import './OpenMap.scss';
import 'ol/ol.css';

class OpenMap extends Component {

    map;
    tmpState;
    iconFeature;
    vectorSource;

    constructor(props) {
        super(props);

        this.state = {
            position: null,
            address: null
        };

        if (props.position) {
            this.tmpState = {
                position: props.position
            };
            this.updateAddress(props.position);
        } else {
            navigator
                .geolocation
                .getCurrentPosition(pos => {
                    this.tmpState = {
                        position: [pos.coords.latitude, pos.coords.longitude]
                    };
                    if (this.state) {
                        this.setState(this.tmpState, () => {
                            this.updateMarker(this.state.position, true, 9);
                            this.updateAddress(this.state.position)
                        });
                    }
                }, () => {
                    this.tmpState = {
                        position: [47, 4]
                    };
                });

        }
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.errors) {
            return {errors: props.errors};
        }
        if (!state.position) {
            state.position = props.position;
        }
        return state;
    }

    componentDidMount() {
        this.setState(this.tmpState, () => {
            this.buildMap();
            this.updateAddress(this.state.position)
        });
    }

    getIconFeature = position => {
        const iconFeature = new Feature({
            geometry: new Point(fromLonLat([position[1], position[0]]))
        });

        const iconStyle = new Style({
            image: new Icon({
                anchor: [
                    14, 18
                ],
                anchorXUnits: 'pixels',
                anchorYUnits: 'pixels',
                src: '/img/map-marker.png'
            })
        });

        iconFeature.setStyle(iconStyle);

        return iconFeature;
    }

    updateAddress = (latLon) => {
        fetch(`/api/geocode/decode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                language: this.context ? this.context.t('LANG') : 'en',
                position: latLon
            })
        })
        .then(res => res.json())
        .then(data => {
            this.setState({
                address: this.formatAddress(data.address)
            });
        })
        .catch(error => {
            console.log(error);
        });
    }

    updateMarker = (position, doCenter, zoomLevel) => {
        if (this.vectorSource) {
            this
                .vectorSource
                .clear();
        }
        if (position) {
            const features = [this.getIconFeature(position)];
            if (this.vectorSource) {
                this
                    .vectorSource
                    .addFeatures(features);
            } else {
                this.vectorSource = new VectorSource({features: features});
            }
        } else {
            this
                .props
                .onClick();
        }
        this.vectorLayer = new VectorLayer({source: this.vectorSource});
        if (this.map) {
            this
                .map
                .addLayer(this.vectorLayer);
            const mapView = this
                .map
                .getView();
            if (doCenter) {
                mapView.setCenter(transform([
                    position[1], position[0]
                ], 'EPSG:4326', 'EPSG:3857'));
            }
            if (zoomLevel) {
                mapView.setZoom(zoomLevel);
            }
        } else {
            this.map = this.getMap(position);
        }
    }

    getMap = (position) => {
        const style = JSON.parse(
            JSON
                .stringify(mapStyle.default)
                .replace(/\{\{LANG\}\}/gi, this.context.t('LANG')
            )
        );
        const map = apply('openmap', style);
        map.addLayer(this.vectorLayer);
        map.setView(new View({
            center: fromLonLat(position
                ? [position[1], position[0]]
                : this.tmpState
                    ? this.tmpState.position
                    : [0, 0]),
            zoom: 8
        }));

        return map;
    }

    formatAddress = address => {
        return `${
            address.city || address.town || address.village || address.state || address.county
        }, ${
            address.country
        }`;
    }

    buildMap = () => {
        this.updateMarker(this.state.position);
        this
            .map
            .on('click', event => {
                const latLon = event.coordinate
                    ? toLonLat(event.coordinate).sort(() => -1)
                    : null;
                this.updateMarker(latLon);
                this.updateAddress(latLon);
                if (this.props.onClick) {
                    this
                        .props
                        .onClick(latLon);
                }

            });
    }

    render() {
        return (
            <div className="openmap-wrapper">
                <div id="openmap" className="openmap">
                    {this.props.children}
                </div>
                <MapFinder address={this.state.address} updatePosition={this.updateMarker}></MapFinder>
            </div>
        );
    }
}
OpenMap.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({auth: state.auth});
export default connect(mapStateToProps, {})(OpenMap);
