import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
class TextInput extends Component {
    render() {
        return (
            <div className={`input-field amber-text col s${this.props.colWidth ? this.props.colWidth : 12}`}>
                <input
                    onChange={this.props.onChange}
                    value={this.props.value}
                    error={this.props.errors}
                    id={this.props.id}
                    type={this.props.type}
                    className={classnames("", {
                        invalid: this.props.errors
                    })}
                />
                <label
                    htmlFor={this.props.id}
                    className={this.props.value !== '' ? 'active' : null}
                >
                    {this.props.children}
                </label>
                {
                    this.props.errors
                        ? (
                            <span className="red-text">{this.context.t(this.props.errors)}</span>
                        ) : ''
                }
            </div>
        );
    }
}
TextInput.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    colWidth: PropTypes.number,
    errors: PropTypes.string
};
TextInput.contextTypes = {
    t: PropTypes.func.isRequired
};
export default connect()(TextInput);
