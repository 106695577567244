const getChildNodes = (result, parentNode, previousKey) => {
    Object.keys(parentNode).forEach(key => {
        const fullKey = previousKey ? `${previousKey}.${key}`: key
        if (typeof parentNode[key] === 'string') {
            result[fullKey] = parentNode[key];
        } else {
            getChildNodes(result, parentNode[key], fullKey);
        }
    });
    return result;
}

export const translations = {};
[
    "en",
    "de",
    "fr",
    "it",
    "ja",
    "ru",
    "es"
].forEach(lang => {
    translations[lang] = getChildNodes({}, require(`./locales/${lang}.locale.json`))
});