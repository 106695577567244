import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import ImageUpload from "../form-elements/ImageUpload";
import TextInput from "../form-elements/TextInput";
import Button from "../form-elements/Button";
import TextArea from "../form-elements/TextArea";
import Page from "../layout/Page";
import { sendConfirmationMail } from '../../utils/sendMail';
import { Modal } from "react-materialize";
import LocationInput from "../form-elements/LocationInput";

class Register extends Component {
    constructor() {
        super();
        this.state = {
            username: "",
            email: "",
            birthdate: "",
            description: "",
            avatar: ImageUpload.defaultImage,
            password: "",
            password2: "",
            confirmationSent: false,
            location: null,
            errors: {}
        };
    }
    componentDidMount() {
        // If logged in and user navigates to Register page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        } 
    }
    static getDerivedStateFromProps(props, state) {
        if (props.errors) {
            return {
                errors: props.errors
            };
        }
        return state;
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    updateImage = (key, value) => {
        this.setState({ [key]: value });
    }
    onSubmit = e => {
        e.preventDefault();
        const newUser = {
            name: this.state.username,
            email: this.state.email,
            birthdate: this.state.birthdate,
            description: this.state.description,
            avatar: this.state.avatar,
            password: this.state.password,
            password2: this.state.password2,
            location: this.state.location
        };

        this.props.registerUser(newUser, this.props.history, () => {
            sendConfirmationMail(this.state.email, this.context.t).then(state => {
                this.setState(state);
            });
        });
    };
    render() {
        const { errors } = this.state;
        return (
            <Page wrapperClassName="container">
                {
                this.state.confirmationSent
                    ? (
                        <Modal
                            className="grey darken-4 white-text"
                            bottomSheet={false}
                            header={this.context.t("PROFILE.CONFIRM_EMAIL")}
                            id={`modal-confirm-email`}
                            open={true}
                            options={{
                                dismissible: false,
                                inDuration: 250,
                                onCloseEnd: null,
                                onCloseStart: null,
                                onOpenEnd: null,
                                onOpenStart: null,
                                opacity: 0.5,
                                outDuration: 250,
                                preventScrolling: true
                            }}
                        >
                            {this.context.t("PROFILE.TEXT_EMAIL_SENT")}
                        </Modal>
                    ) : ''
                }
                <p className="grey-text text-darken-1">
                    {this.context.t("PROFILE.TEXT_LOGIN")}
                    <br />
                    <Link className="amber-text" to="/login">
                        {this.context.t("PROFILE.LINKTEXT_LOGIN")}
                    </Link>
                </p>
                <form noValidate onSubmit={this.onSubmit}>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.username}
                        errors={errors.username}
                        id={'username'}
                        type={'text'}
                    >
                        {this.context.t("FORMS.LABEL_NAME")}
                    </TextInput>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.email}
                        errors={errors.email}
                        id={'email'}
                        type={'email'}
                    >
                        {this.context.t("FORMS.LABEL_EMAIL")}
                    </TextInput>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.birthdate}
                        errors={errors.birthdate}
                        id={'birthdate'}
                        type={'date'}
                    >
                        {this.context.t("FORMS.LABEL_BIRTHDATE")}
                    </TextInput>
                    <TextArea
                        onChange={this.onChange}
                        value={this.state.description}
                        errors={errors.description}
                        id={'description'}
                    >
                        {this.context.t("FORMS.LABEL_DESCRIPTION")}
                    </TextArea>
                    <LocationInput
                        onChange={this.onChange}
                        value={this.state.location}
                        errors={errors.location}
                        id={'location'}
                    >
                        {this.context.t("FORMS.LABEL_LOCATION")}
                    </LocationInput>
                    <ImageUpload
                        updateImage={this.updateImage.bind(this)}
                        value={this.state.avatar}
                        errors={errors.avatar}
                        id={'avatar'}
                    >
                        {this.context.t("FORMS.LABEL_AVATAR")}
                    </ImageUpload>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.password}
                        errors={errors.password}
                        id={'password'}
                        type={'password'}
                    >
                        {this.context.t("FORMS.LABEL_PWD")}
                    </TextInput>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.password2}
                        errors={errors.password2}
                        id={'password2'}
                        type={'password'}
                    >
                        {this.context.t("FORMS.LABEL_PWD_CONFIRM")}
                    </TextInput>
                    <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                        <Button
                            buttonType={'primary'}
                            type={'submit'}
                        >
                            {this.context.t("FORMS.BTN_SIGNUP")}
                        </Button>
                    </div>
                </form>
            </Page>
        );
    }
}
Register.propTypes = {
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
Register.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { registerUser }
)(withRouter(Register));