import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class Page extends Component {

    render() {
        return (
            <div className={`row ${this.props.wrapperClassName}`}>
                <div className="col s12 center-align">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
Page.propTypes = {
    auth: PropTypes.object.isRequired,
    wrapperClassName: PropTypes.string
};
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { }
)(Page);
