import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import OpenMap from "../layout/OpenMap";

class LocationInput extends Component {

    markerStyle;

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            position: props.value ? props.value.reverse() : props.value
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.errors) {
            return {errors: props.errors};
        }
        state.position = props.value;
        return state;
    }

    onMapClick = latLon => {
        this
            .props
            .onChange({
                target: {
                    'id': this.props.id,
                    'value': latLon ? latLon.reverse() : latLon
                }
            })
    };

    componentDidMount() {
        this.setState({isLoaded: true});
    }

    render() {
        return this.state.isLoaded
            ? (
                <div className="col s12">
                    <label
                        htmlFor={''}
                        className={'active'}
                        style={{
                        display: 'block',
                        textAlign: 'left',
                        marginBottom: '-.3rem'
                    }}>
                        {this.props.children}
                    </label>
                    <OpenMap position={this.state.position} onClick={this.onMapClick}/> {this.props.errors
                        ? (
                            <span className="red-text">{this
                                    .context
                                    .t(this.state.errors)}</span>
                        )
                        : ''
}
                </div>
            )
            : '';
    }
}
LocationInput.propTypes = {
    id: PropTypes.string,
    value: PropTypes.array,
    errors: PropTypes.string,
    onChange: PropTypes.func.isRequired
};
LocationInput.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect()(LocationInput);
