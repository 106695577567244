import axios from "axios";
import {
    GET_ERRORS, ADD_MEDIA, LIST_MEDIA, IMG_BASE64_CONVERT
} from "./types";
// Register User
export const addMedia = (mediaData) => dispatch => {
    axios
        .post("/api/media/add", mediaData)
        .then(res => 
            dispatch({
                tyoe: ADD_MEDIA,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err
            })
        );
};

export const saveImageBase64 = (mediaData) => dispatch => {
    return axios
        .post("/api/media/saveimagebase64", mediaData)
        .then(res => 
            dispatch({
                type: IMG_BASE64_CONVERT,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err
            })
        );
};

export const listMedia = (filter) => dispatch => {
    return axios
        .post('/api/media/list', filter)
        .then(res =>
            dispatch({
                type: LIST_MEDIA,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err
            })
        );
};