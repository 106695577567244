import axios from "axios";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../../actions/authActions";
import { MessageSend } from "../messages/send/MessageSend";
import ReactDOM from 'react-dom';
import Page from '../layout/Page';
import moment from 'moment';
import { getDistance } from 'geolib';
import './Dashboard.scss';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            cardHeight: 0,
            activeUser: null
        };
        this.updateOnlineUsers();
        this.userListInterval = window.setInterval(() => {
            this.updateOnlineUsers();
        }, 2000);
    }
    updateHeight = () => {
        var cardImage = ReactDOM.findDOMNode(this).querySelector('.card');
        this.setState({ cardHeight: cardImage ? cardImage.clientWidth : 0 });
    }

    componentWillUnmount = () => {
        window.clearInterval(this.userListInterval);
    }
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };
    distanceToUser = (user1, user2) => user1.location && user2.location
        ? Math.round(getDistance(
            {
                latitude: user1.location.coordinates[0],
                longitude: user1.location.coordinates[1]
            }, {
                latitude: user2.location.coordinates[0],
                longitude: user2.location.coordinates[1]
            }) / 1000)
        : null;
    // Get online users
    updateOnlineUsers = () => {
        axios
            .post("/api/users/find", {})
            .then(res => {
                const oldestPossible = moment().subtract(125, 'years');
                const onlineTime = moment().subtract(16, 'seconds');
                const currentUser = res.data.users.filter(user => user._id === this.props.auth.user.id)[0];
                const users = res.data.users
                    .filter(user => oldestPossible.isBefore(user.birthdate))
                    .filter(user => user._id !== this.props.auth.user.id)
                    .map(user => {
                        user.isOnline = onlineTime.isBefore(user.lastUpdate);
                        user.distance = this.distanceToUser(currentUser, user, 'K');
                        return user;
                    }).sort((a, b) => {
                        const outerSpace = 1000000;
                        const result = a.isOnline > b.isOnline
                            ? -1
                            : a.isOnline < b.isOnline
                                ? 1
                                : (a.distance || outerSpace) < ( b.distance || outerSpace)
                                    ? -1
                                    : 1;
                        return result;
                    });
                this.setState({
                    users: users
                });
                this.updateHeight();
            })
            .catch(() => {
                this.setState({
                    users: []
                });
            });
    };

    toggleActive = (userId, forceInactive) => {
        this.setState({
            activeUser: forceInactive ? null : userId
        });
    }

    userList = () => {
        const flexBasis = `${
            window.innerWidth > 992
                ? this.state.users.length > 2
                    ? 30
                    : this.state.users.length > 1
                        ? 45
                        : 100
                : window.innerWidth > 600
                    ? this.state.users.length > 1
                        ? 45
                        : 100
                    : 100
            }%`;
        if (this.state.users) {
            return this.state.users.map(user => (
                <div className={`dashboard-user card grey darken-3${
                        user.isOnline
                            ? ' dashboard-user--online'
                            : ''
                        }${
                        this.state.activeUser === user._id
                            ? ' dashboard-user--active'
                            : ' dashboard-user--inactive'
                        }`}
                    key={user._id}
                    onClick={() => {
                        this.toggleActive(user._id);
                    }}
                    onMouseLeave={() => {
                        this.toggleActive(user._id, true);
                    }}
                    style={{
                        flexBasis: flexBasis
                    }}>
                    <div className="dashboard-user-image-wrapper grey darken-3">
                        <div className="dashboard-user-image card-image"
                            style={{
                                backgroundImage: `url(${user.avatar})`,
                                height: `${this.state.cardHeight}px`
                            }}>
                            <span className="dashboard-user-title card-title amber-text">{user.name}</span>
                            <span className="dashboard-user-age card-subtitle white-text">
                                {
                                    `${moment().diff(user.birthdate, 'years')} ${this.context.t('COMMON.YEARS')}`
                                }{
                                    user.distance ? (
                                        this.context.t("COMMON.DISTANCE_TEXT")
                                        .replace('{dist}', `${user.distance}${this.context.t("COMMON.KILOMETERS")}`)    
                                    ) : ''
                                }
                            </span>
                        </div>
                    </div>
                    <div className="card-content grey darken-3">
                        {user.description.split('\n')
                            .filter(element => element.trim() !== '')
                            .map((element, index) => (<p key={index}>{element}</p>))}
                    </div>
                    <MessageSend
                        className=""
                        auth={this.props.auth}
                        errors={{}}
                        to={user}
                    ></MessageSend>
                </div>
            ));
        }
    }
    render() {
        return (
            <Page wrapperClassName="dashboard">
                <div className="row dashboard-list">
                    {this.userList()}
                </div>
            </Page>
        );
    }
}
Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
Dashboard.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default withRouter(connect(
    mapStateToProps,
    { logoutUser }
)(Dashboard));