import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PayPalButton } from "react-paypal-button-v2";

class PayPal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMounted: false
        }
    }

    componentDidMount() {
        this.setState({
            isMounted: true
        })
    }

    render() {
        const languageCode = this.context.t("COMMON.PAYPAL_LOCALE_CODE");
        return this.state.isMounted
            ? (
                <PayPalButton
                    amount={this.props.amount}
                    options={{
                        clientId: "AQd8-XfMbJmXoqkc8mx7ukRjGBa_-Ps8DIMuOWUDowNVsLDB-XO6gf00doE4u-JbW-tUq9q_cOXdf-pW",
                        locale: languageCode
                    }}
                    shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                    onSuccess={this.props.onSuccess}
                />    
            ) : (
                <div></div>
            );
    }
}
PayPal.propTypes = {
    amount: PropTypes.string,
    onSuccess: PropTypes.func
};
PayPal.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default withRouter(connect(
    mapStateToProps,
    { }
)(PayPal));