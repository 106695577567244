import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { listMedia } from "../../actions/mediaActions";
import VideoUpload from "../videoupload/VideoUpload";
class MediaList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mediaItems: []
        };
        this.props.listMedia({})
          .then(res => {
              this.setState({
                  mediaItems: res.payload.filter(mediaElem => mediaElem.description !== 'preview image')
              })
          });
    }

    mediaList = () => {
        return this.state.mediaItems.map(media => (
            <video className="responsive-video"
                id={media._id}
                poster={media.url.replace('.mp4', '_preview.png')}
                controls
                key={media._id}
            >
                <source src={media.url} type="video/mp4" />
            </video>                
        ));
    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col s12 center-align">
                        <h4>
                            Your media
                        </h4>
                        <div className="row">
                            {this.mediaList()}
                            <VideoUpload></VideoUpload>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
MediaList.propTypes = {
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(
    mapStateToProps,
    { listMedia }
)(MediaList);