import axios from "axios";
import {
    GET_ERRORS, ADD_MESSAGE, LIST_MESSAGES
} from "./types";
// Register User
export const sendMessage = (messageData) => dispatch => {
    axios
        .post("/api/messages/add", messageData)
        .then(res => dispatch({
                tyoe: ADD_MESSAGE,
                payload: res.data
            })
        )
        .catch(err => dispatch({
                type: GET_ERRORS,
                payload: err
            })
        );
};

export const listMessages = (filter) => dispatch => {
    return axios
        .post('/api/messages/list', filter)
        .then(res =>
            dispatch({
                type: LIST_MESSAGES,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err
            })
        );
};