import React, {Component} from "react";
import Icon from "react-materialize/lib/Icon";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class MapFinder extends Component {

    request;
    controller = new AbortController()

    constructor(props) {
        super(props);

        this.state = {
            result: '',
            query: '',
            address: '',
            isQueryVisible: true
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.errors) {
            return {errors: props.errors};
        }

        if (state.address !== '' && props.address !== state.address) {
            state.isQueryVisible = false;
        }
        state.address = props.address;

        return state;
    }

    updateResult = query => {
        this.controller.abort();
        this.controller = new AbortController()
        const signal = this.controller.signal;
        const formData = new FormData();
        formData.append('query', query);
        if (query.length > 1) {
            this.request = fetch(`/api/geocode/lookup`, {
                method: 'POST',
                signal: signal,
                headers: {
                    'Content-Type': 'application/json'
                },
                    body: JSON.stringify({
                        query: query,
                        language: this.context ? this.context.t('LANG') : 'en'
                    })
                })
                .then(res => res.json())
                .then(data => {
                    this
                        .props
                        .updatePosition([data.lat, data.lon], true, 8);
                })
                .catch(error => {
                    if (error.name === 'AbortError') {
                        return;
                    }
                });    
        }
    }

    showQuery = (e) => {
        this.setState({
            query: '',
            position: null,
            isQueryVisible: true
        }, () => {
            this
                .props
                .updatePosition();
        });
    }

    render() {
        return (
            <div className="map-finder">
                {
                    this.state.isQueryVisible
                        ? (
                            <fieldset>
                                <input
                                    type="text"
                                    className="white-text"
                                    value={this.state.query}
                                    onChange={event => {
                                        this.setState({query: event.target.value});
                                        this.updateResult(event.target.value);
                                    }}/>
                            </fieldset>
                        ) : (
                            <fieldset>
                                <input
                                    type="text"
                                    className="white-text"
                                    readOnly={true}
                                    value={this.props.address || ''} />
                                <button onClick={e => {
                                    e.preventDefault();
                                    this.showQuery(e);
                                }}>
                                    <Icon>
                                        close
                                    </Icon>
                                </button>
                            </fieldset>
                        )
                }
                <span>
                    {this.state.result}
                </span>
            </div>
        );
    }
}
MapFinder.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({auth: state.auth});
export default connect(mapStateToProps, {})(MapFinder);

