import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
class ImageUpload extends Component {

    fileReader = null;
    static defaultImage = '/img/default-avatar.png';
    errorMessages = {
        415: 'Please only upload JPG, PNG or GIF image files.'
    }
    
    constructor(props) {
        super(props);
        this.state = {
            imageUrl: props.value
                ? props.value
                : ImageUpload.defaultImage,
            errors: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        state.imageUrl = props.value;
        return state;
    }

    addFile = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('uploadImage', event.target.files[0]);
        return axios
            .post("/upload", formData)
            .then(res => res.data)
            .then(data => {
                const fileUrl = process.env.PUBLIC_URL + data.imageUrl;
                this.setState({ imageUrl: fileUrl, errors: '' });
                this.props.updateImage(this.props.id, fileUrl);
            })
            .catch(err => {
                this.setState({ errors: this.errorMessages[err.response.status] });
            });
    }

    render() {
        return (
            <div className="col s12">
                <label htmlFor={''}
                    className={'active'}
                    style={{
                        display: 'block',
                        textAlign: 'left',
                        marginBottom: '1rem'
                    }}>
                    {this.props.children}
                </label>
                <input type="file" 
                    id="uploadImage"
                    name="uploadImage"
                    className={'hide'}
                    onChange={this.addFile}
                />
                <img src={this.state.imageUrl} alt={'avatar'}
                    className={'responsive-img center-align'} />
                <div className="row">
                    <label htmlFor={'uploadImage'}
                        className={'btn btn-large waves-effect waves-light hoverable amber black-text'}>
                        {this.props.children}
                    </label>
                </div>
                {
                    this.props.errors
                        ? (
                            <span className="red-text">{this.context.t(this.state.errors)}</span>
                        ) : ''
                }
            </div>
        );
    }
}
ImageUpload.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    updateImage: PropTypes.func,
    errors: PropTypes.string
};
ImageUpload.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect()(ImageUpload);
