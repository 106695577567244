import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import VideoSnapshot from 'video-snapshot';
import Button, { ButtonClasses } from "../form-elements/Button";
import { addMedia, saveImageBase64 } from "../../actions/mediaActions";
class VideoUpload extends Component {

    fileReader = null;
    
    constructor(props) {
        super(props);
        this.state = {
            snapshoter: null,
            imageUrl: '',
            videoUrl: '',
            previewPosition: 0,
            currentPosition: 0,
            isLoading: false
        };
    }

    takeSnapshot = () => {
        const previewPosition = document.getElementById('video-preview').currentTime;

        const savePreviewImage = (base64data) => {
            this.props.saveImageBase64({
                base64data: base64data,
                fileName: this.state.videoUrl.replace('.mp4', '_preview.png')
            }).then(res => {
                this.setState({
                    imageUrl: res.payload.imageUrl,
                    previewPosition: previewPosition
                });
            });
        }
    
        this.state.snapshoter.takeSnapshot(previewPosition).then(result => {
            savePreviewImage(result);
        });
    }

    addFile(event) {
        const { user } = this.props && this.props.auth ? this.props.auth : null;
        const videoBlob = event.target.files[0];
        this.setState({
            isLoading: true,
            snapshoter: new VideoSnapshot(videoBlob)
        });
        const formData = new FormData();
        formData.append('uploadVideo', videoBlob);  
        formData.append('user_id', user.id);    
        fetch(`/uploadvideo`, {
          method: 'POST',
          body: formData
        })
        .then(res => res.json())
        .then(data => {
            this.setState({
                videoUrl: process.env.PUBLIC_URL + data.videoUrl,
                videoFile: videoBlob,
                isLoading: false
            });
            this.takeSnapshot();
        })
        .catch(error => {
            console.log(error);
        })
    }

    onSubmit = e => {
        e.preventDefault();
        const { user } = this.props && this.props.auth ? this.props.auth : null;
        if (user) {
            const newVideo = {
                url: this.state.videoUrl,
                user_id: user.id,
                description: 'video vile'
            };
            this.props.addMedia(newVideo);
            const newPreviewImage = {
                url: this.state.imageUrl,
                user_id: user.id,
                description: 'preview image'
            };
            this.props.addMedia(newPreviewImage);
        }
    };

    render() {
        return (
            <div className="row center" style={{ marginTop: "4rem" }}>
                <div className={'col s12'}>
                    {(this.state.videoUrl) &&
                    <video className="responsive-video"
                        onTimeUpdate={(event) => {
                            this.setState({
                                currentPosition: event.target.currentTime
                            });
                        }}
                        id={'video-preview'}
                        poster={this.state.imageUrl 
                            ? this.state.imageUrl + '?st=' + new Date().toUTCString() 
                            : ''
                        }
                        controls
                    >
                        <source src={this.state.videoUrl} type="video/mp4" />
                    </video>                
                }
                </div>
                <div className="col s12">
                    <input type="file" 
                        id="uploadVideo"
                        name="uploadVideo"
                        className={'hide'}
                        onChange={(event) => { 
                            this.addFile(event)
                        }}
                    />
                    {(
                        this.state.videoUrl
                        && this.state.previewPosition !== this.state.currentPosition
                    ) &&
                        <Button
                            buttonType={'secondary'}
                            onClick={this.takeSnapshot}
                        >
                            Choose snapshot
                        </Button>
                    }
                    <label htmlFor={'uploadVideo'}
                        className={ButtonClasses.secondary}>
                        Upload video
                    </label>
                    {(this.state.videoUrl && this.state.imageUrl) &&
                        <Button
                            buttonType={'primary'}
                            onClick={this.onSubmit}
                        >
                            Save Video
                        </Button>
                    }
                    <span className="red-text">{this.props.errors}</span>
                </div>
            </div>
        );
    }
}
VideoUpload.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    updateVideo: PropTypes.func,
    errors: PropTypes.string
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(
    mapStateToProps,
    { addMedia, saveImageBase64 }
)(withRouter(VideoUpload));