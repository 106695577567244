import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser, updateLastUpdate } from "./actions/authActions";
import { Provider } from "react-redux";
import I18n from "redux-i18n"
import store from "./store";
import Navbar from "./components/layout/Navbar";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import VideoUpload from "./components/videoupload/VideoUpload";
import MediaList from "./components/medialist/MediaList";
import MessageSend from "./components/messages/send/MessageSend";
import MessageList from "./components/messages/list/MessageList";
import Profile from "./components/auth/Profile";
import "./App.scss";
import Chat from "./components/chat/Chat";
import UnderConstruction from "./components/layout/UnderConstruction";
import ChangePassword from "./components/auth/ChangePassword";
import ResetPassword from "./components/auth/ResetPassword";
import {translations} from "./translations";
import detectBrowserLanguage from 'detect-browser-language'
import Home from "./components/layout/Home";
import { listMessages } from "./actions/messageActions";
let decoded, keepAliveInterval;

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
        user: decoded && decoded.id ? decoded : null,
        messages: []
    };

    if (this.state.user) {
      this.intervalledUpdate();
      keepAliveInterval = window.setInterval(this.intervalledUpdate, 5000);
    }
  }

  componentWillUnmount(){
    window.clearInterval(keepAliveInterval);
  }

  updateMessages = () => {
    updateLastUpdate({id: this.state.user.id});
    store.dispatch(listMessages({ to: this.state.user.id })).then(res => {
      if (res.payload && res.payload.filter) {
        this.setState({
            messages: res.payload
        });
      }
    });
  };

  intervalledUpdate = () => {
    if (this.state.user.id) {
      this.updateMessages();
    } else {
        if (keepAliveInterval) {
            window.clearInterval(keepAliveInterval);
            keepAliveInterval = undefined;
        }
    }

  }

  getLang = () => {
    const browserLanguage = detectBrowserLanguage().split('-')[0];
    if (Object.keys(translations).indexOf(browserLanguage) > -1) {
      return browserLanguage;
    }
    return "en";
  }

  render() {
    return (
      <Provider store={store}>
        <I18n translations={translations} initialLang={this.getLang()} fallbackLang="en">
          <Router>
            <Switch>
              <Route exact path="/" component={UnderConstruction /* localStorage.jwtToken ? Home : Landing */} />            
              <Route path="/:subpath">
                <div className="app">
                  <div className="grad-vertical">
                      <div className="grad-horizontal"></div>
                  </div>
                  <div className="container content-root center grey darken-4">
                    <Navbar messages={this.state.messages} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/login/:confirmationId?" component={Login} />
                    <Route exact path="/pwd-reset/:confirmationId?" component={ResetPassword} />
                    <Route exact path="/home" component={Home} />
                    <Switch>
                      <PrivateRoute exact path="/pwd-change" component={ChangePassword} />
                      <PrivateRoute exact path="/dashboard" component={Dashboard} />
                      <PrivateRoute exact path="/profile" component={Profile} />
                      <PrivateRoute exact path="/videoupload" component={VideoUpload} />
                      <PrivateRoute exact path="/medialist" component={MediaList} />
                      <PrivateRoute exact path="/chat" component={Chat} />
                      <PrivateRoute exact path="/messagelist">
                        <MessageList messages={this.state.messages} updateMessages={this.updateMessages} />
                      </PrivateRoute>
                      <PrivateRoute exact path="/sendmessage" component={MessageSend} />
                    </Switch>
                  </div>
                </div>
              </Route>
            </Switch>
          </Router>
        </I18n>
      </Provider>
    );
  }
}
export default App;