import React, { Component } from "react";
import { Collapsible, CollapsibleItem, Button, Modal } from "react-materialize";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Icon from "react-materialize/lib/Icon";
import Page from "../../layout/Page";
import MessageSend from "../send/MessageSend";
import '../Message.scss';
class MessageList extends Component {

    static getDerivedStateFromProps = (props, state) => {
        state.messages = props.messages;
        return state;
    }

    constructor(props) {
        super(props);
        this.state = {
            messages: props.messages
        };
    }

    render() {
        return (
            <Page wrapperClassName="message-list">
                <Collapsible>
                { 
                    this.state.messages ? this.state.messages.map(message => {
                        return (
                            <CollapsibleItem
                                className={message.openingDate ? 'read' : 'unread'}
                                onClick={() => {
                                    axios.get('/api/messages/markread/' + encodeURI(message._id))
                                        .then(res => {
                                            if (res && res.data) {
                                                this.setState({ messages: this.state.messages
                                                    .map(el => (el._id === message._id
                                                        ? Object.assign(el, { openingDate: res.data.openingDate })
                                                        : el
                                                    ))
                                                }, () => {
                                                    this.props.updateMessages();
                                                });
                                            }
                                        });
                                }}
                                key={message._id}
                                header={(
                                    <span className="message-header">
                                        <span className="message-header-title">
                                            <span className="message-header-title-user amber-text">
                                                {message.from.name}
                                            </span>
                                            {message.title}
                                        </span>
                                        <Modal
                                            className="grey darken-4 white-text"
                                            actions={[
                                                <Button
                                                    className="btn-delete-check amber"
                                                    floating
                                                    icon={<Icon>check</Icon>}
                                                    small
                                                    node="button"
                                                    waves="light"
                                                    modal='close'
                                                    onClick={() => {
                                                        axios.get('/api/messages/delete/' + encodeURI(message._id))
                                                            .then(() => {
                                                                this.props.updateMessages();
                                                            });
                                                    }}
                                                />,
                                                <Button
                                                    className="btn-delete-close amber"
                                                    floating
                                                    icon={<Icon>close</Icon>}
                                                    small
                                                    node="button"
                                                    waves="light"
                                                    modal="close"
                                                />
                                            ]}
                                            bottomSheet={false}
                                            header={this.context.t("MESSAGES.CONFIRMATION_TITLE")}
                                            id={`modal-delete-${message._id}`}
                                            options={{
                                                dismissible: true,
                                                endingTop: '10%',
                                                inDuration: 250,
                                                onCloseEnd: null,
                                                onCloseStart: null,
                                                onOpenEnd: null,
                                                onOpenStart: null,
                                                opacity: 0.5,
                                                outDuration: 250,
                                                preventScrolling: true,
                                                startingTop: '4%'
                                            }}
                                            trigger={(
                                                <Button
                                                    className="message-header-delete amber"
                                                    floating
                                                    icon={<Icon>delete</Icon>}
                                                    small
                                                    node="button"
                                                    waves="light"
                                                />
                                            )}
                                            >
                                            {this.context.t("MESSAGES.DELETE_CONFIRMATION{name}", {name: message.from.name})}
                                        </Modal>
                                    </span>
                                )}
                                icon={<img 
                                    src={message.from.avatar} 
                                    alt={message.from.name}
                                    className={'message-icon'}
                                />}
                            >
                                {message.content}
                                <MessageSend
                                    auth={this.props.auth}
                                    errors={{}}
                                    to={message.from}
                                ></MessageSend>
                            </CollapsibleItem>
                        )
                    }) 
                : ''}
                </Collapsible>
            </Page>
        );
    }
}
MessageList.propTypes = {
    auth: PropTypes.object.isRequired,
    updateMessages: PropTypes.func.isRequired,
    messages: PropTypes.array
};
MessageList.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(
    mapStateToProps,
    { }
)(MessageList);