import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pwdChange, logoutUser } from "../../actions/authActions";
import TextInput from "../form-elements/TextInput";
import Button from "../form-elements/Button";
import Page from "../layout/Page";
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.auth.user.id,
            pwdOld: '',
            pwdNew: '',
            pwdConfirm: '',
            errors: {}
        };
    }
    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.auth.isAuthenticated) {
            props.history.push("/login");

        }
        if (props.errors) {
            return {
                errors: props.errors
            };
        }
        return null;
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    onSubmit = e => {
        e.preventDefault();
        const data = {
            id: this.state.id,
            pwdOld: this.state.pwdOld,
            pwdNew: this.state.pwdNew,
            pwdConfirm: this.state.pwdConfirm
        };

        this.props.pwdChange(data, () => {
            this.setState({ verificationSuccess: true });
            setTimeout(() => {
                this.props.logoutUser();
            }, 10000);
        });
    };
    render() {
        const { errors } = this.state;
        return (
            <Page wrapperClassName="container">
                {
                    this.state.verificationSuccess
                        ? (
                            <div className="success">
                                {this.context.t("PROFILE.SUCCESS_MESSAGE_PWD_CHANGE")}

                            </div>
                                        
                        ) : ''
                }
                <form noValidate onSubmit={this.onSubmit}>
                    <input type="hidden" id="id" value={this.state.id} />
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.pwdOld}
                        errors={errors.pwdOld}
                        id={'pwdOld'}
                        type={'password'}
                    >
                        {this.context.t("FORMS.LABEL_PWD_CURRENT")}
                    </TextInput>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.pwdNew}
                        errors={errors.pwdNew}
                        id={'pwdNew'}
                        type={'password'}
                    >
                        {this.context.t("FORMS.LABEL_PWD_NEW")}
                    </TextInput>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.pwdConfirm}
                        errors={errors.pwdConfirm}
                        id={'pwdConfirm'}
                        type={'password'}
                    >
                        {this.context.t("FORMS.LABEL_PWD_NEW_CONFIRM")}
                    </TextInput>
                    <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                        <Button
                            buttonType={'primary'}
                            type={'submit'}
                        >
                            {this.context.t("FORMS.BTN_CHANGE_PWD")}
                        </Button>
                    </div>
                </form>
            </Page>
        );
    }
}
ChangePassword.propTypes = {
    pwdChange: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    id: PropTypes.string,
    pwdOld: PropTypes.string,
    pwdNew: PropTypes.string,
    pwdConfirm: PropTypes.string
};
ChangePassword.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    id: state.id,
    pwdOld: state.pwdOld,
    pwdNew: state.pwdNew,
    pwdConfirm: state.pwdConfirm,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { pwdChange, logoutUser }
)(ChangePassword);