import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { listMessages } from "../../actions/chatMessageActions";
import axios from "axios";
import TextInput from "../form-elements/TextInput";
import Button from "../form-elements/Button";
import ChatRoomSelector from "./ChatRoomSelector";
import { ChatHelpers } from "./ChatHelpers";
import ChatList from "./ChatList";
import * as _ from "lodash";
import './Chat.scss';
class Chat extends Component {

    interval = null;

    constructor(props) {
        super(props);
        this.updateMessages();
        this.interval = setInterval(this.updateMessages, 1000);
        const inputId = props.recipient ? `${props.recipient.id}_content` : 'content';
        this.state = {
            messages: [],
            filteredMessages: [],
            lastMessageId: null,
            recipient: null,
            [inputId]: '',
            inputId: inputId,
            userId: undefined,
            errors: {}
        };
    }

    updateMessages = () => {
        this.props.listMessages()
            .then(res => {
                if (res.payload && this.state) {
                    const messages = res.payload;
                    const filteredMessages = ChatHelpers.getFilteredList(messages, {
                        ...this.props.auth.user,
                        _id: this.props.auth.user.id
                    }, this.state.recipient);
                    let recipient = this.state.recipient;
                    const lastMessage = messages.sort((a, b) => a.date < b.date ? 1 : -1)[0];
                    const lastMessageId = lastMessage ? lastMessage._id : null;
                    const hasNewMessages = (this.state.lastMessageId !== lastMessageId);
                    if (
                        (hasNewMessages)
                        && (
                            lastMessage
                            && lastMessage.to
                            && lastMessage.to._id === this.props.auth.user.id
                        )
                    ) {
                        recipient = lastMessage.from;
                        this.updateMessages();
                    }
                    const tmpState = {
                        userId: this.state.recipient ? this.state.recipient._id : undefined,
                        messages: messages,
                        filteredMessages: filteredMessages,
                        recipient: recipient,
                        lastMessageId: lastMessageId
                    };
                    if (
                        !this.state.recipient
                        || !_.isEqual(this.state.userId, tmpState.userId)
                        || !_.isEqual(this.state.messages.length, tmpState.messages.length)
                        || !_.isEqual(this.state.filteredMessages.length, tmpState.filteredMessages.length)
                        || !_.isEqual(this.state.recipient._id, tmpState.recipient._id)
                        || !_.isEqual(this.state.lastMessageId, tmpState.lastMessageId)
                    ) {
                        this.setState(tmpState);
                    }
                }
                return null;
            })
            .catch(error => { console.log(error); });
    };

    componentWillUnmount = () => {
        clearInterval(this.interval);
    };

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    
    onSubmit = e => {
        e.preventDefault();
        if (this.state[this.state.inputId] === '') {
            return null;
        }
        const messageData = {
            from: this.props.auth.user.id,
            to: this.state.recipient ? this.state.recipient._id : null,
            content: this.state[this.state.inputId]
        };
        axios
            .post("/api/chat/messages/add", messageData)
            .then(() => {
                this.setState({
                    [this.state.inputId]: '',
                    errors: {}
                })
            })
            .catch(err => {
                this.setState({
                    errors: {
                        [this.state.inputId]: 'Oops, something went wrong, please try again later!'
                    }
                })
            });
    };

    updateUser = user => {
        this.setState({
            recipient: user
        }, () => {
            this.updateMessages();
        });
    };

    render() {
        const { errors } = this.state;
        return !this.state.messages
            ? (
            <div>{this.context.t("CHAT.NO_MESSAGES")}</div>
            ) : (
            <div className={'chat'}>
                <div className={'hide-on-large-only chat-room-selector-mobile'}>
                    <ChatRoomSelector
                        isMobile={true}
                        messages={this.state.messages}
                        recipient={this.state.recipient}
                        updateUser={this.updateUser}
                    ></ChatRoomSelector>
                </div>
                <form noValidate className={'chat-input z-depth-0 container grey darken-4'} onSubmit={this.onSubmit}>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state[this.state.inputId]}
                        errors={errors[this.state.inputId]}
                        colWidth={10}
                        id={this.state.inputId}
                        type={'text'}
                    >
                        {this.context.t("FORMS.LABEL_MESSAGE")}
                    </TextInput>
                    <div className={'input-field col s2'}>
                        <Button
                            buttonType={'primary'}
                            onClick={this.onSubmit}
                        >
                            {this.context.t("FORMS.BTN_SEND")}
                        </Button>
                        <ChatRoomSelector
                            className={'hide-on-med-and-down'}
                            messages={this.state.messages}
                            recipient={this.state.recipient}
                            updateUser={this.updateUser}
                        ></ChatRoomSelector>
                    </div>
                </form>
                <ChatList
                    messages={this.state.filteredMessages}
                    updateUser={this.updateUser}
                ></ChatList>
            </div>
        );
    }
}
Chat.propTypes = {
    auth: PropTypes.object.isRequired,
    recipient: PropTypes.object,
    addUserTab: PropTypes.func
};
Chat.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(
    mapStateToProps,
    { listMessages }
)(Chat);