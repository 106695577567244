import axios from "axios";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextInput from "../../form-elements/TextInput";
import TextArea from "../../form-elements/TextArea";
import Button from "../../form-elements/Button";
export class MessageSend extends Component {
    constructor(props) {
        super(props);
        const fieldPrefix = props.to ? props.to._id : 'blind';
        this.state = {
            to: null,
            fieldPrefix: fieldPrefix,
            [`${fieldPrefix}_title`]: '',
            [`${fieldPrefix}_content`]: '',
            errors: {}
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.errors) {
            return {
                errors: props.errors
            };
        }
        return state;
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    onSubmit = e => {
        e.preventDefault();
        const messageData = {
            from: this.props.auth.user.id,
            to: this.props.to._id,
            title: this.state[`${this.state.fieldPrefix}_title`],
            content: this.state[`${this.state.fieldPrefix}_content`]
        };
        axios
            .post("/api/messages/add", messageData)
            .then(res => {
                this.setState({
                    [`${this.state.fieldPrefix}_title`]: '',
                    [`${this.state.fieldPrefix}_content`]: '',
                })
            })
            .catch(err => {
                console.log(err);
            });
        // this.props.sendMessage(JSON.stringify(messageData));
    };
    render() {
        const { errors } = this.state;
        return (
            <div style={{ paddingBottom: '1em' }} className="message-send center">
                <form noValidate onSubmit={this.onSubmit} className={this.props.className}>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state[`${this.state.fieldPrefix}_title`]}
                        errors={errors.title}
                        id={`${this.state.fieldPrefix}_title`}
                        type={'text'}
                    >
                        {this.context.t("FORMS.LABEL_TITLE")}
                    </TextInput>
                    <TextArea
                        onChange={this.onChange}
                        value={this.state[`${this.state.fieldPrefix}_content`]}
                        errors={errors.content}
                        id={`${this.state.fieldPrefix}_content`}
                    >
                        {this.context.t("FORMS.LABEL_MESSAGE")}
                    </TextArea>
                    <Button
                        buttonType={'primary'}
                        onClick={this.onSubmit}
                    >
                        {this.context.t("FORMS.BTN_SEND")}
                    </Button>
                </form>
            </div>
        );
    }
}
MessageSend.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired
};
MessageSend.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    {}
)(MessageSend);