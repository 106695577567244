import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import TextInput from "../form-elements/TextInput";
import Button from "../form-elements/Button";
import Page from "../layout/Page";
import { sendConfirmationMail } from '../../utils/sendMail';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            errors: {}
        };
        const confirmationId = props.match.params.confirmationId;
        if (confirmationId) {
            fetch(`/api/users/verify/${confirmationId}`, {
                method: 'GET'
              })
              .then(res => res.json())
              .then(res => {
                  if (res.status === 'failed') {
                      const errors = this.state.errors;
                      errors.verificationFailed = true;
                      this.setState({
                          errors: errors
                      });
                      sendConfirmationMail(this.state.email, this.context.t).then(state => {
                        this.setState(state);
                    });
    
                } else {
                    this.setState({
                        verificationSuccess: true
                    });
                    window.setTimeout(() => {
                        props.history.push("/login");
                        this.setState({
                            verificationSuccess: true
                        });
                    }, 10000);
                  }
              })
              .catch(() => {
                this.state.errors.verificationFailed = true;
              });   
        }
    }
    componentDidMount() {
        // If logged in and user navigates to Login page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.auth.isAuthenticated) {
            props.history.push("/dashboard"); // push user to dashboard when they login

        }
        if (props.errors) {
            return {
                errors: props.errors
            };
        }
        return null;
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    onSubmit = e => {
        e.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password
        };

        this.props.loginUser(userData, data => {
            if (data.notVerified) {
                sendConfirmationMail(this.state.email, this.context.t).then(state => {
                    this.setState(state);
                });
            } else {
                window.location.reload();
            }
        });
    };
    render() {
        const { errors } = this.state;
        return (
            <Page wrapperClassName="container">
                <p className="grey-text text-darken-1">
                    {this.context.t("LOGIN.TEXT_REGISTER")}
                    <br />
                    <Link className="amber-text" to="/register">
                        {this.context.t("LOGIN.LINKTEXT_REGISTER")}
                    </Link>
                </p>
                <p className="grey-text text-darken-1">
                    {this.context.t("LOGIN.TEXT_RESET_PWD")}
                    <br />
                    <Link className="amber-text" to="/pwd-reset">
                        {this.context.t("LOGIN.LINKTEXT_RESET_PWD")}
                    </Link>
                </p>
                <div className="errors">
                {
                    this.state.errors.notVerified
                        ? (
                            <div>
                                {this.context.t("LOGIN.ERROR_NOT_VERIFIED")}
                            </div>
                        ) : this.state.errors.verificationFailed
                        ? (
                            <div>
                                {this.context.t("LOGIN.ERROR_VERIFICATION_FAILED")}
                            </div>
                        ) : (this.state.errors.passwordincorrect || this.state.errors.emailnotfound)
                        ? (
                            <div>
                                {this.context.t("LOGIN.ERROR_LOGIN_FAILED")}
                            </div>
                        ) : ''
                }{
                    this.props.location.search.indexOf('registered') > -1 ? (
                        <div>
                            {this.context.t("LOGIN.ERROR_NOT_VERIFIED")}
                        </div>
                    ) : ''
                }
                </div>
                {
                    this.state.verificationSuccess
                        ? (
                            <div className="success">
                                {this.context.t("LOGIN.SUCCESS_MESSAGE")}
                            </div>
                                        
                        ) : ''
                }
                <form noValidate onSubmit={this.onSubmit}>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.email}
                        errors={errors.email}
                        id={'email'}
                        type={'email'}
                    >
                        {this.context.t("FORMS.LABEL_EMAIL")}
                    </TextInput>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.passwort}
                        errors={errors.password}
                        id={'password'}
                        type={'password'}
                    >
                        {this.context.t("FORMS.LABEL_PWD")}
                    </TextInput>
                    <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                        <Button
                            buttonType={'primary'}
                            type={'submit'}
                        >
                            {this.context.t("FORMS.BTN_LOGIN")}
                        </Button>
                    </div>
                </form>
            </Page>
        );
    }
}
Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
Login.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Login);