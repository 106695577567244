import axios from "axios";
import React from "react";
import { Email, Item, renderEmail, A, Span, Box } from 'react-html-email';

export const sendMail = (message) => {
    const amber = '#ffc107';
    const emailHTML = renderEmail(
        <Email title={message.subject}>
            <Box align="center">
                <Item align="center" style={{
                    padding: '30px'
                }}>
                    <Box align="center">
                        <Item align="center">
                            <Span fontSize={40} color={amber}>
                                フケ
                            </Span>
                        </Item>
                        <Item align="center">
                            <Span fontSize={80} color={amber}>
                                専
                            </Span>
                        </Item>
                    </Box>
                </Item>
                <Item align="center">
                    { message.html }
                </Item>
            </Box>
        </Email>
    )

    const formData = new FormData()
    formData.append('to', message.to);
    formData.append('subject', message.subject);
    formData.append('content', message.content);
    formData.append('html', emailHTML);
    
    return fetch(`/send-mail`, {
        method: 'POST',
        body: formData
      })
      .then(res => res.json())
      .then(data => data)
      .catch(error => error);
}

export const sendConfirmationMail = (email, translate) => axios
        .post("/api/users/find", { email: email })
        .then(res => res.data.users[0])
        .then(user => {
            const linkUrl = `${window.location.protocol}//${window.location.host}/login/${user.confirmationId}`;

            sendMail({
                to: user.email,
                subject: translate("PROFILE.TEXT_EMAIL_SUBJECT"),
                content: `${translate("PROFILE.TEXT_EMAIL_CONTENT")} ${linkUrl}`,
                html: (
                    <Span fontSize={20}>
                        {translate("PROFILE.TEXT_EMAIL_CONTENT")}
                        <br />
                        <A href={linkUrl}>
                            {translate("PROFILE.LINKTEXT_EMAIL_CONTENT")}
                        </A>.
                    </Span>
                )
            }).then(() => {
                return {
                    confirmationSent: true
                };
            }).catch(error => {
                return {
                    errors: error.response.data.payload
                };
            });
        })
        .catch(error => {
            return {
                errors: error.response.data.payload
            };
        });
