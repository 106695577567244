import React, { Component } from "react";
import PropTypes from "prop-types";
import Logo from './Logo';
import { connect } from "react-redux";
import Page from "./Page";
import PayPal from "../auth/PayPal";

class Home extends Component {

    render() {
        return (
            <Page>
                <div
                    className="center-align">
                        <span className="logo--pulse">
                            <Logo></Logo>
                        </span>
                    <h4>
                        {this.context.t("HOME.TITLE")}
                    </h4>
                    <span className="flow-text white-text text-darken-1">
                        <p>
                            {this.context.t("HOME.TEXT_1")}
                        </p>
                        <p>
                            {this.context.t("HOME.TEXT_2")}                                
                        </p>
                        <PayPal amount="1.01"
                            onSuccess={(details) => {
                                alert("Transaction completed by " + details.payer.name.given_name);
                            }}
                        />    
                    </span>
                </div>
            </Page>
        );
    }
}
Home.contextTypes = {
    t: PropTypes.func.isRequired
};
export default connect()(Home);
