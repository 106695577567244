import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Logo from "./Logo";
import './UnderConstruction.scss';

class UnderConstruction extends Component {

    constructor(props) {
        
        super(props);

        this.state = {
            rotation: {
                angle: 0,
                direction: 0
            }
        }
        
        const styles = {
            maxWidth: '100vw',
            maxHeight: '100vh',
            overflow: 'hidden',
            display: 'flex'
        };
        document.querySelectorAll('html, body').forEach(elem => {
            Object.keys(styles).forEach(key => {
                elem.style[key] = styles[key];
            });
        });
    }

    setRotationState = (angle, direction) => this.setState({
        rotation: {
            angle: angle,
            direction: direction
        }
    });

    foolUser = event => {
        event.preventDefault();

        if (this.state.rotation.angle > 10000) {
            alert(this.context.t('COMING_SOON.STOP_FOOLING'));
            this.setRotationState(10080, 3);
        } else {
            this.setRotationState(
                this.state.rotation.angle + 90,
                Math.round(Math.random() * 2)
            );
        }
    }

    render() {
        return (
            <div style={{ 
                    display: 'flex',
                    flexDirection: 'row'
                }}
                className={`under-construction valign-wrapper`}>
                <div className="content-wrapper"
                        style={{
                            transform: `rotate3d(${
                                    this.state.rotation.direction === 0 ? 1 : 0
                                }, ${
                                    this.state.rotation.direction === 1 ? 1 : 0
                                }, ${
                                    this.state.rotation.direction === 2 ? 1 : 0
                                }, ${
                                    this.state.rotation.angle
                                }deg)`
                            }}>
                    <div className="content cpmtainer white-text">
                        <div
                            className="center-align">
                                <span className="logo--pulse">
                                    <Logo></Logo>
                                </span>
                            <h4>
                                {this.context.t("COMING_SOON.TITLE")}
                            </h4>
                            <span className="flow-text white-text text-darken-1">
                                <p>
                                {this.context.t("COMING_SOON.TEXT_1")}
                                </p>
                                <p>
                                    {this.context.t("COMING_SOON.TEXT_2")}                                
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="overlay" onContextMenu={this.foolUser}></div>
            </div>
        );
    }
}
UnderConstruction.contextTypes = {
    t: PropTypes.func.isRequired
};
export default connect()(UnderConstruction);
