import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
class TextArea extends Component {
    render() {
        return (
            <div className="input-field col s12 amber-text">
                <textarea
                    onChange={this.props.onChange}
                    value={this.props.value}
                    error={this.props.errors}
                    id={this.props.id}
                    className={'materialize-textarea ' + classnames("", {
                        invalid: this.props.errors
                    })}
                >
                    {this.props.value}
                </textarea>
                <label
                    htmlFor={this.props.id}
                    className={this.props.value !== '' ? 'active' : null}
                >
                    {this.props.children}
                </label>
                {
                    this.props.errors
                        ? (
                            <span className="red-text">{this.context.t(this.props.errors)}</span>
                        ) : ''
                }
            </div>
        );
    }
}
TextArea.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    errors: PropTypes.string
};
TextArea.contextTypes = {
    t: PropTypes.func.isRequired
};
export default connect()(TextArea);
