import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUser, findUser } from "../../actions/authActions";
import ImageUpload from "../form-elements/ImageUpload";
import TextInput from "../form-elements/TextInput";
import Button from "../form-elements/Button";
import TextArea from "../form-elements/TextArea";
import axios from "axios";
import Page from '../layout/Page';
import LocationInput from "../form-elements/LocationInput";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.auth.user.id,
            username: '',
            birthdate: '',
            description: '',
            avatar: '',
            location: null,
            errors: {}
        };

        axios
            .post("/api/users/find", { _id: this.props.auth.user.id })
            .then(res => res.data.users[0])
            .then(user => {
                this.setState({
                    isLoaded: true,
                    username: user.name,
                    birthdate: user.birthdate.substr(0, 10),
                    description: user.description,
                    avatar: user.avatar,
                    location: user.location
                        ? user.location.coordinates
                        : null
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    static getDerivedStateFromProps(props, state) {
        if (props.errors) {
            return {
                errors: props.errors
            };
        }
        return null;
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    updateImage = (key, value) => {
        this.setState({ [key]: value });
    }
    onSubmit = e => {
        e.preventDefault();
        const newUser = {
            id: this.props.auth.user.id,
            name: this.state.username,
            birthdate: this.state.birthdate,
            description: this.state.description,
            avatar: this.state.avatar,
            location: this.state.location
        };
        this.props.updateUser(newUser, this.props.history);
    };
    render() {
        const { errors } = this.state;
        return this.state.isLoaded ? (
            <Page wrapperClassName="container">
                <form noValidate onSubmit={this.onSubmit} className="center-align">
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.username}
                        errors={errors.username}
                        id={'username'}
                        type={'text'}
                    >
                        {this.context.t("FORMS.LABEL_NAME")}
                    </TextInput>
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.birthdate}
                        errors={errors.birthdate}
                        id={'birthdate'}
                        type={'date'}
                    >
                        {this.context.t("FORMS.LABEL_BIRTHDATE")}
                    </TextInput>
                    <TextArea
                        onChange={this.onChange}
                        value={this.state.description}
                        errors={errors.description}
                        id={'description'}
                    >
                        {this.context.t("FORMS.LABEL_DESCRIPTION")}
                    </TextArea>
                    <LocationInput
                        onChange={this.onChange}
                        value={this.state.location}
                        errors={errors.location}
                        id={'location'}
                    >
                        {this.context.t("FORMS.LABEL_LOCATION")}
                    </LocationInput>
                    <ImageUpload
                        updateImage={this.updateImage.bind(this)}
                        value={this.state.avatar}
                        errors={errors.avatar}
                        id={'avatar'}
                    >
                        {this.context.t("FORMS.LABEL_AVATAR")}
                    </ImageUpload>
                    <div className="row">
                        <div className="col s12">
                            <Button
                                buttonType={'primary'}
                                onClick={() => {
                                    this.props.history.push('/pwd-change');
                                }}
                            >
                                {this.context.t("FORMS.BTN_CHANGE_PWD")}
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <Button
                                buttonType={'primary'}
                                type={'submit'}
                            >
                                {this.context.t("FORMS.BTN_UPDATE")}
                            </Button>
                        </div>
                    </div>
                </form>
            </Page>
        ) : '';
    }
}
Profile.propTypes = {
    updateUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
Profile.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { updateUser, findUser }
)(withRouter(Profile));