import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ChatHelpers } from "./ChatHelpers";
import moment from 'moment';
class ChatList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            recipient: null,
            errors: {},        
            hasNewMessages: true,
            newestMessageId: null
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.errors) {
            return {
                errors: props.errors
            };
        }
        state.hasNewMessages = false;
        if (props.messages) {
            const lastMessage = props.messages.sort((a, b) => a.date < b.date ? 1 : -1)[0];
            const lastMessageId = lastMessage ? lastMessage._id : null;
            const hasNewMessages = (lastMessageId !== state.lastMessageId);

            if (hasNewMessages) {
                state.hasNewMessages = true;
                state.lastMessageId = lastMessageId;
                state.messages = ChatHelpers.mapMessagesToUsers(props.messages);
            }
        }
        return state;
    }

    formatTime = date => moment(date).format('HH:mm:ss');

    render() {
        if (this.state.hasNewMessages) {
            const listWrapper = document.querySelector('.chat-list-wrapper');
            if (listWrapper) {
                setTimeout(() => {
                    window.scrollTo(0, 1000000000);
                });
            }
        }
        return !this.state.messages
            ? (
            <div>{this.context.t("CHAT.NO_MESSAGES")}</div>
            ) : (
            <div className={'chat-list-wrapper'}>
            {
                this.state.messages.map(message => (message.user && message.messages) ? (
                    <div 
                        key={(message.user
                            ? message.user._id : 'dummy') + (message.messages ? message.messages[0]._id
                            : ''
                        )}
                        className={'chat-list-row'}>
                        <span className={`chat-user ${
                            message.user._id === this.props.auth.user.id
                                ? 'chat-user--left'
                                : 'chat-user--right'
                        }`}>
                            <img 
                                src={message.user.avatar} 
                                alt={message.user.name}
                                className={'message-icon'}
                            />
                            <a href={`#${message.user.name}`}
                                onClick={() => {
                                    this.props.updateUser(message.user);
                                }}
                                className={'chat-user-label amber-text'}>
                                {message.user.name}
                            </a>
                        </span>
                        <ul className={'chat-list container grey darken-3'}>
                        { 
                            message.messages.map(message => {
                                return (
                                <li className={'chat-entry'} key={message._id}>
                                    <span className="chat-entry-date">
                                        {this.formatTime(message.date)}
                                    </span>
                                    {message.content}
                                </li>
                            )})
                        }
                        </ul>
                    </div>
                ) : '')
            }
            &nbsp;
            </div>
        );
    }
}
ChatList.propTypes = {
    auth: PropTypes.object.isRequired,
    messages: PropTypes.array,
    updateUser: PropTypes.func
};
ChatList.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(
    mapStateToProps,
    { }
)(ChatList);