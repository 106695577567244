import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pwdReset, findUser } from "../../actions/authActions";
import TextInput from "../form-elements/TextInput";
import Button from "../form-elements/Button";
import Page from "../layout/Page";
import { sendMail } from "../../utils/sendMail";
import { Span, A } from 'react-html-email';
import axios from "axios";
class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.confirmationId,
            email: '',
            pwdNew: '',
            pwdConfirm: '',
            errors: {}
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.errors) {
            return {
                errors: props.errors
            };
        }
        return state;
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    onSubmit = e => {
        e.preventDefault();
        const data = {
            id: this.state.id,
            email: this.state.email,
            pwdNew: this.state.pwdNew,
            pwdConfirm: this.state.pwdConfirm
        };

        if (data.id) {
            this.props.pwdReset(data, () => {
                this.setState({ verificationSuccess: true });
                setTimeout(() => {
                    this.props.history.push('/login');
                }, 10000);
            });    
        } else {
            axios
                .post("/api/users/find", {
                    email: data.email
                })
                .then(res => res.data.users[0])
                .then(user => {
                    if (user) {
                        const linkUrl = `${window.location.protocol}//${window.location.host}/pwd-reset/${user._id}`;
                        sendMail({
                            to: data.email,
                            subject: this.context.t("RESET_PWD.TEXT_EMAIL_SUBJECT"),
                            content: `${this.context.t("RESET_PWD.TEXT_EMAIL_CONTENT")} ${linkUrl}`,
                            html: (
                                <Span fontSize={20}>
                                    {this.context.t("RESET_PWD.TEXT_EMAIL_CONTENT")}
                                    <br />
                                    <A href={linkUrl}>
                                        {this.context.t("RESET_PWD.LINKTEXT_EMAIL_CONTENT")}
                                    </A>.
                                </Span>
                            )
                        }).then(data => {
                            this.setState({
                                emailSent: true
                            });
                        }).catch(error => {
                            this.setState({
                                emailSent: true
                            });
                        });
                    } else {
                        this.setState({
                            emailSent: true
                        });
                    }
                })
                .catch(err => {
                    this.setState({
                        emailSent: true
                    });
                });
        }
    };
    render() {
        const { errors } = this.state;
        return (
            <Page wrapperClassName="container">
                {
                    this.state.verificationSuccess
                        ? (
                            <div className="success">
                                {this.context.t("RESET_PWD.SUCCESS_MESSAGE")}
                            </div>
                                        
                        ) : this.state.emailSent
                            ? (
                                <div className="success">
                                    {this.context.t("RESET_PWD.TEXT_CHECK_INBOX")}
                                </div>
                                            
                            ) : ''
                } {
                    
                }
                <form noValidate onSubmit={this.onSubmit}>
                    <input type="hidden" id="id" value={this.state.id} />
                    <TextInput
                        onChange={this.onChange}
                        value={this.state.email}
                        errors={errors.email}
                        id={'email'}
                        type={'text'}
                    >
                        {this.context.t("FORMS.LABEL_EMAIL")}
                    </TextInput>
                    { this.state.id 
                        ? (
                            <div>
                                <TextInput
                                    onChange={this.onChange}
                                    value={this.state.pwdNew}
                                    errors={errors.pwdNew}
                                    id={'pwdNew'}
                                    type={'password'}
                                >
                                    {this.context.t("FORMS.LABEL_PWD_NEW")}
                                </TextInput>
                                <TextInput
                                    onChange={this.onChange}
                                    value={this.state.pwdConfirm}
                                    errors={errors.pwdConfirm}
                                    id={'pwdConfirm'}
                                    type={'password'}
                                >
                                    {this.context.t("FORMS.LABEL_PWD_NEW_CONFIRM")}
                                </TextInput>
                            </div>
                        ) : ''
                    }
                    <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                        <Button
                            buttonType={'primary'}
                            type={'submit'}
                        >
                            {this.context.t("FORMS.BTN_RESET_PWD")}
                        </Button>
                    </div>
                </form>
            </Page>
        );
    }
}
ResetPassword.propTypes = {
    pwdReset: PropTypes.func.isRequired,
    findUser: PropTypes.func.isRequired,
    id: PropTypes.string,
    email: PropTypes.string,
    pwdNew: PropTypes.string,
    pwdConfirm: PropTypes.string
};
ResetPassword.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    id: state.id,
    email: state.email,
    pwdNew: state.pwdNew,
    pwdConfirm: state.pwdConfirm,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { pwdReset, findUser }
)(ResetPassword);